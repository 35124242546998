import React, { useState, useEffect } from 'react';
import { RxLinkedinLogo } from 'react-icons/rx';
import { FaFacebook, FaTelegram } from 'react-icons/fa';
import { BsWhatsapp, BsInstagram, BsTwitter } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export default function EventFooter() {
  const [version, setVersion] = useState({ buildVersion: '', gitHash: '', buildTimestamp: '' });

  useEffect(() => {
    axios
      .get(API_URL + `/api/version`)
      .then((response) => {
        setVersion(response.data);
      })
      .catch((error) => {
        console.error('Error fetching version:', error);
      });
  }, []);

  return (
    <div className='text-center p-3 pt-5' style={{ backgroundColor: '#ffffffeb', position: 'relative' }}>
      
      {/* Social Icons */}
      <Link to={`https://www.linkedin.com/sharing/share-offsite/?url=`}>
        <RxLinkedinLogo className='fs-1 pt-3' style={{ color: '#1da1f2' }} size={40} />
      </Link>

      <Link to={`https://www.facebook.com/sharer.php?u=`}>
        <FaFacebook className='fs-1 pt-4' style={{ color: '#3b5998' }} size={40} />
      </Link>

      <Link to={`https://www.instagram.com/direct/inbox/?url=`}>
        <BsInstagram className='fs-1 pt-4' style={{ color: '#F77737' }} size={40} />
      </Link>

      <Link to={`https://twitter.com/share?url=`}>
        <BsTwitter className='fs-1 pt-4' style={{ color: '#1DA1F2' }} size={40} />
      </Link>

      <Link to={`https://api.whatsapp.com/send?text=`}>
        <BsWhatsapp className='fs-1 pt-4' style={{ color: '#25d366' }} size={40} />
      </Link>

      <Link to={`https://telegram.me/share/url?url=`}>
        <FaTelegram className='fs-1 pt-4' style={{ color: '#1da1f2' }} size={40} />
      </Link>

      {/* Footer Information */}
      <div className="footer-info" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', position: 'absolute', bottom: 0, left: 0, padding: '10px 20px' }}>
        {/* Left corner text */}
        <div style={{ textAlign: 'left', fontSize: '14px', color: 'gray' }}>
          Powered by Cynefian
        </div>

        {/* Right corner text */}
        <div style={{ textAlign: 'right', fontSize: '14px', color: 'gray' }}>
          {new Date().getFullYear().toString()} &copy; 
          <a
            href='https://cynefian.com/'
            target='_blank'
            rel='noopener noreferrer'
            className='text-gray-800 text-hover-primary'
            style={{ marginLeft: '5px' }}
          >
            Cynefian
          </a>
        </div>
      </div>
    </div>
  );
}
