import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
//import { Button } from '../button/Button';
import './Navbar.css'

const Navbar = ({ event,logo }) => {
const [events, setEvent]= useState({});
console.log(event)
  const [click, setClick] = useState(false);

  const [button, setButton] = useState(true)
  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false)

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false)
    } else {
      setButton(true)
    }
  }

  useEffect(() => {
    if(event){
      setEvent(event);
    }
    showButton()

  }, [event]);

  window.addEventListener('resize', showButton);
  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
        <Link
          to={`/event/${event?.id}`}
          className="navbar-logo"
          onClick={closeMobileMenu}
        >
          {event?.logo && (
            <img
              src={`${event?.imageMeta},${event?.logo}`}
              alt="Event Logo"
              style={{ width: '50px', height: 'auto', marginRight: '10px' }}
            />
          )}
          {event?.title}
        </Link>
          <div className="menu-icon" onClick={handleClick} >
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} style={{ fontSize: '25px', color: '#f4f5f8' }} />
          </div>

          <ul className={click ? 'nav-menu active' : 'nav-menu'} >
            <li className="nav-item">
              <Link to={{ pathname:`/event/${event?.id}`}} className='nav-links' onClick={closeMobileMenu} >
                HOME
              </Link>
            </li>
            <li className="nav-item">
              <Link to={{ pathname: `/event/${event?.id}/agenda`}} className='nav-links' onClick={closeMobileMenu} >
                AGENDA
              </Link>
            </li>
            <li className="nav-item">
              <Link to={{ pathname: `/event/${event?.id}/agenda`}} className='nav-links' onClick={closeMobileMenu} >
              ARTICLES
              </Link>
            </li>
            <li className="nav-item">
              <Link to={{ pathname: `/event/${event?.id}/speakers`}} className='nav-links' onClick={closeMobileMenu} >
                SPEAKERS
              </Link>
            </li>

            <li className="nav-item">
              <Link to={{ pathname: `/event/${event?.id}/sponsors` }} className='nav-links' onClick={closeMobileMenu} >
                SPONSORS
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link to={{ pathname: `/event/${events?.orgcode}/${events?.title}/venue` }} className='nav-links' onClick={closeMobileMenu} >
                VENUE
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to={{ pathname: `/event/${event?.id}/eventSignup`  }} className='nav-links' onClick={closeMobileMenu} >
                SIGN IN </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Navbar