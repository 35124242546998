import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Description({ event }) {

    const navigate = useNavigate();

    const handleSignUp = () => {
        navigate(`/event/${event?.id}/eventSignup`);
    };

    return (
        <div className='card-header pt-10'>
            <div className='col-12'>
                <div className='card card-xl-stretch mb-xl-8' 
                    style={{ 
                        backgroundColor: '#728df9', 
                        color: '#fbf9f9', 
                        fontSize: '1.5rem',
                        padding: '30px 70px',
                    }}
                >
                    <div className='d-flex' style={{ height: '100%', padding: '30px' }}>
                        
                        {/* Left half for the brief description */}
                        <div className='card-title d-flex flex-column' style={{ flex: 1 }}>
                            <div>{event?.description}</div>
                        </div>
    
                        {/* Right half for Register Now */}
                        <div className='d-flex' 
                            style={{ 
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%', 
                            }}
                        >
                            <button
                                onClick={handleSignUp}
                                style={{
                                    padding: '10px 20px',
                                    backgroundColor: '#fbf9f9',
                                    color: '#728df9',
                                    fontSize: '1.5rem',
                                    borderRadius: '5px',
                                    border: 'none',
                                    cursor: 'pointer'
                                }}
                            >
                                Register Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
