import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../app/modules/auth';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const SidebarMenuMain = () => {
  const intl = useIntl()

  const storedData = localStorage.getItem('kt-auth-react-v');
  const data = storedData ? JSON.parse(storedData) : null;
  const role = data?.role;
  const orgid = data?.orgid;
  const permissions = data?.authorities || [];
  const [hasConditions, setHasConditions] = useState(false);


  const { eventId } = useParams();
  const {auth}= useAuth();
  const [loading, setLoading] = useState(false);

  const [selectedEventId, setSelectedEventId] = useState(eventId || null);

  const handleEventSelection = (eventid) => {
    setSelectedEventId(eventid);
  };

  useEffect(() => {
    if (eventId) {
      setSelectedEventId(eventId);
    }
    else {
      setSelectedEventId(null);
    }
  }, [eventId]);

  const hasPermission = (permission) => {
    return permissions.some((p) => p.authority === permission);
  };


  useEffect(() => {
    setLoading(true);
    axios
        .post(API_URL + `/api/advertisement/exhibitor/check-conditions`, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
            },
        })
        .then(response => {
          setHasConditions(response.data);
          console.log(response.data);
            setLoading(false);
        })
        .catch(error => {
            toast.error(error);
            setLoading(false);
        });

}, [auth?.token, eventId]);

  return (
    <>

      {`${role}` === "ROLE_SUPER" ?
        <div>
          <SidebarMenuItem
            to='/SuperDashboard'
            icon='element-11'
            title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
            fontIcon='bi-app-indicator'
          />

          <SidebarMenuItemWithSub title='Application Management' icon='some-files'>
            <SidebarMenuItem to='/organizationlist' title='Organizations' hasBullet={true} />
            <SidebarMenuItem to='/userAcc' title='User Management' hasBullet={true} />
            <SidebarMenuItem to={`/advertisements`} title='Advertisement' hasBullet={true} />

          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub to='/exhibitorConfig' title='Exhibitor Configuration' fontIcon='LiaTasksSolid' icon='category'>
            <SidebarMenuItem to='/uspSeek' title='USP Seek' hasBullet={true} />
            <SidebarMenuItem to='/USPIntentions' title='USP Intentions' hasBullet={true} />

          </SidebarMenuItemWithSub>


          <SidebarMenuItemWithSub title='Subscription' icon='switch'>
            <SidebarMenuItem to='/subscriptionTypes' title='Subscription Types' hasBullet={true} />
            <SidebarMenuItem to='/subscriptionFeatures' title='Subscription Features' hasBullet={true} />
            <SidebarMenuItem to={`/subscriptionPermissions`} title='Subscription Permissions' hasBullet={true} />

          </SidebarMenuItemWithSub>

          <SidebarMenuItem to='/EventSectors' title='Event Sectors' fontIcon='LiaTasksSolid' icon='home-1' />

          <SidebarMenuItemWithSub to='/systemSetting' title='System Settings' fontIcon='LiaTasksSolid' icon='setting-2'>
            <SidebarMenuItem to='/AdvSetting' title='Advertisement Settings' hasBullet={true} />
            <SidebarMenuItem to='/activitytype' title='Event Activity Types' hasBullet={true} />
            <SidebarMenuItem to='/orgtype' title='Organization Types' hasBullet={true} />
            <SidebarMenuItem to='/platformfee' title='Platform Fee' hasBullet={true} />
            <SidebarMenuItem to='/features' title='System Features' hasBullet={true} />
            <SidebarMenuItem to='/permissions' title='System Permissions' hasBullet={true} />
            <SidebarMenuItem to='/taskpriorities' title='Task Priorities' hasBullet={true} />
            <SidebarMenuItem to='/taskstatus' title='Task Status' hasBullet={true} />





          </SidebarMenuItemWithSub>
          <SidebarMenuItem to='/auditlog' icon='code' title='Audit Log' fontIcon='bi-layers' />

        </div>
        : ""}

      {role === "ROLE_ADMIN" && (
        <div>
          <div onClick={() => handleEventSelection(null)}>

            <SidebarMenuItem
              to='/dashboard'
              icon='element-11'
              title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
              fontIcon='bi-app-indicator'
            />
          </div>
          <div onClick={() => handleEventSelection(null)}>
            {hasPermission('MANAGE EVENTS') && (
              <SidebarMenuItem to='/listEvents' icon='switch' title='Events' fontIcon='bi-layers' />
            )}
          </div>

          {selectedEventId !== null && (
            <div className='menu-item'>


              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Event Dashboard</span>
              </div>


              <SidebarMenuItemWithSub
                title='Event Details'
                fontIcon='bi-archive'
                icon='element-plus'
                onClick={() => handleEventSelection(eventId)}
              >
                {hasPermission('MANAGE EVENTS') && (
                  <SidebarMenuItem to={`/eventDetails/${eventId}`} title='Event Details' hasBullet={true} />
                )}
                {/* <SidebarMenuItem to={`/roles/${eventId}`} title='Event Roles' hasBullet={true} /> */}

                {hasPermission('MANAGE ARTICLE') && (
                  <SidebarMenuItem to={`/eventArticles/${eventId}`} title='Event Articles' hasBullet={true} />
                )}
                {hasPermission('MANAGE SESSIONS') && (
                  <SidebarMenuItem to={`/eventSession/${eventId}`} title='Session Management' hasBullet={true} />
                )}
                {hasPermission('MANAGE WHITELABEL') && (
                  <SidebarMenuItem to={`/whitelabeling/${eventId}`} title='WhiteLabeling' hasBullet={true} />
                )}

              </SidebarMenuItemWithSub>

              <SidebarMenuItemWithSub
                title='Event Managment'
                icon='profile-circle'
                fontIcon='bi-person'
              >
                {/* <SidebarMenuItem to={`/socialmedias/${eventId}`} title='Social Media Integration' hasBullet={true} /> */}
                {hasPermission('MANAGE COMMITEEMEMBER') && (
                  <SidebarMenuItem
                    to={`/commiteemembers/${eventId}`}
                    title='Committee Members'
                    hasBullet={true}
                  />
                )}
                {hasPermission('MANAGE EVENTROLE') && (
                  <SidebarMenuItem
                    to={`/eventRoles/${eventId}`}
                    title='Role User Permission'
                    hasBullet={true}
                  />
                )}
                {hasPermission('MANAGE EXHIBITOR') && (
                  <SidebarMenuItem
                    to={`/exhibitors/${eventId}`}
                    title='Exhibitors'
                    hasBullet={true}
                  />
                )}

                {hasPermission('MANAGE SPEAKERS') && (
                  <SidebarMenuItem
                    to={`/Speakers/${eventId}`}
                    title='Speakers'
                    hasBullet={true}
                  />
                )}
                {hasPermission('MANAGE SPONSORS') && (
                  <SidebarMenuItem
                    to={`/Sponsonrs/${eventId}`}
                    title='Sponsors'
                    hasBullet={true}
                  />
                )}


                <SidebarMenuItem
                  to={`/SponsonrsType/${eventId}`}
                  title='Sponsors Type'
                  hasBullet={true}
                />


                <SidebarMenuItem
                  to={`/platformfee/${eventId}`}
                  title='Platform Fee'
                  hasBullet={true}
                />


                {hasPermission('MANAGE ATTENDEES') && (
                  <SidebarMenuItem
                    to={`/attendees/${eventId}`}
                    title='Attendees'
                    hasBullet={true}
                  />
                )}
              </SidebarMenuItemWithSub>

              <SidebarMenuItemWithSub to={`/stalls/${eventId}`} icon='shop' title='Stalls'>

                {hasPermission('MANAGE STALL') && (
                  <SidebarMenuItem
                    to={`/stalltype/${eventId}`}
                    title='Stall Type'
                    hasBullet={true}
                  />
                )}

                {hasPermission('MANAGE STALLCHART') && (
                  <SidebarMenuItem
                    to={`/stallChart/${eventId}`}
                    title='Stalls Chart'
                    hasBullet={true}
                  />
                )}

                {hasPermission('MANAGE STALLEXHIBITOR') && (
                  <SidebarMenuItem
                    to={`/stallexhibitorList/${eventId}`}
                    title='Stall Exhibitors'
                    hasBullet={true}
                  />
                )}


              </SidebarMenuItemWithSub>

              <SidebarMenuItemWithSub title='Tasks' icon='some-files'>
                {hasPermission('MANAGE TASKS') && (
                  <SidebarMenuItem
                    to={`/tasklist/${eventId}`}
                    title='Task List'
                    hasBullet={true}
                  />
                )}


              </SidebarMenuItemWithSub>
            

              <SidebarMenuItemWithSub title='Advertisement Details' fontIcon='bi-archive' 
                icon='element-plus'>
               {hasPermission('MANAGE ADVERTISEMENT') && (
                  <SidebarMenuItem
                    to={`/adminadv/${eventId}`}
                    title='Advertisement '
                    hasBullet={true}
                  />
                  )}
             

              </SidebarMenuItemWithSub>



              <SidebarMenuItemWithSub title='Exhibitor Advertisement ' fontIcon='bi-archive' 
                icon='element-plus'>
               {hasPermission('MANAGE ADVERTISEMENT') && (
                  <SidebarMenuItem
                    to={`/adminadds/${eventId}`}
                    title='Advertisement settings'
                    hasBullet={true}
                  />
                  )}
             

              </SidebarMenuItemWithSub>
              {/* <SidebarMenuItemWithSub
                to='/events/transaction'
                title='Transaction'
                icon='element-7'
                fontIcon='bi-layers'
              >
                <SidebarMenuItem to='/transaction/payment' title='Payment' hasBullet={true} />
              </SidebarMenuItemWithSub>

              <SidebarMenuItem to={`/events/tasks/${eventId}`} title='Activities' fontIcon='LiaTasksSolid' icon='menu' /> */}
            </div>
          )}


          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Others</span>
            </div>
          </div>

          <SidebarMenuItemWithSub
            title='Transaction'
            icon='element-7'
            fontIcon='bi-layers'
          >
            {hasPermission('MANAGE TRANSACTION') && (
              <SidebarMenuItem
                to='transactionhistory'
                title='Transaction History'
                hasBullet={true}
              />
            )}
          </SidebarMenuItemWithSub>


          <div className='menu-item'>
            {/* <SidebarMenuItem
              to={`/auditlog/${orgid}`} icon='code' title='Audit Log'>

            </SidebarMenuItem> */}
            <SidebarMenuItem
              to={`/live`} icon='code' title='Live Streaming'>

            </SidebarMenuItem>

          </div>

        </div>
      )}

      {`${role}` === "ROLE_EXHIBITOR" ?
        <div>
          <div onClick={() => handleEventSelection(null)}>

            <SidebarMenuItem
              to='/Exhibitordashboard'
              icon='element-11'
              title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
              fontIcon='bi-app-indicator'
            />
          </div>
          <div onClick={() => handleEventSelection(null)}>
            <SidebarMenuItem to='/events' icon='switch' title='Events' fontIcon='bi-layers' />
          </div>
          {selectedEventId !== null && (
            <div className='menu-item'>


              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Exhibitor Dashboard</span>
              </div>


              <SidebarMenuItemWithSub
                to={`/eventcontroller/${eventId}`}
                title='Exhibitor'
                fontIcon='bi-archive'
                icon='element-plus'
                onClick={() => handleEventSelection(eventId)}
              >


                <SidebarMenuItem to={`/ExhibitorDetails/${eventId}`} title='Connection Page' hasBullet={true} />
                <SidebarMenuItem to={`/Exhibitorcontent/${eventId}`} title='Exhibitor Content' hasBullet={hasConditions} />
                <SidebarMenuItem to={`/connections/${eventId}`} title='Connections' hasBullet={true} />
                <SidebarMenuItem to={`/stalldetails/${eventId}`} title='Stall Details' hasBullet={true} />



              </SidebarMenuItemWithSub>



            </div>
          )}


          <div className='menu-item'>

            <SidebarMenuItem
              to={`/uspSeeks`} icon='some-files' title='USP Seeks'>

            </SidebarMenuItem>
            <SidebarMenuItem
              to={`/uspIntens`} icon='some-files' title='USP Intentions'>

            </SidebarMenuItem>

          </div>

        </div> : ""}


      {`${role}` === "ROLE_COMMITEE_MEMBER" ?
        <div>
          <div onClick={() => handleEventSelection(null)}>

            <SidebarMenuItem
              to='/committeedash'
              icon='element-11'
              title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
              fontIcon='bi-app-indicator'
            />
          </div>
          <div onClick={() => handleEventSelection(null)}>
            <SidebarMenuItem to='/listEvents' icon='switch' title='Events' fontIcon='bi-layers' />
          </div>
          {selectedEventId !== null && (
            <div className='menu-item'>


              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Event Dashboard</span>
              </div>


              <SidebarMenuItemWithSub
                title='Event Details'
                fontIcon='bi-archive'
                icon='element-plus'
                onClick={() => handleEventSelection(eventId)}
              >
                <SidebarMenuItem to={`/eventDetails/${eventId}`} title='Event Details' hasBullet={true} />
                {/* <SidebarMenuItem to={`/roles/${eventId}`} title='Event Roles' hasBullet={true} /> */}
                <SidebarMenuItem to={`/eventArticles/${eventId}`} title='Event Articles' hasBullet={true} />
                <SidebarMenuItem to={`/eventAdds/${eventId}`} title='Advertisement' hasBullet={true} />
                <SidebarMenuItem to={`/eventSession/${eventId}`} title='Session Management' hasBullet={true} />
                <SidebarMenuItem to={`/whitelabeling/${eventId}`} title='WhiteLabeling' hasBullet={true} />

              </SidebarMenuItemWithSub>
              <SidebarMenuItemWithSub
                title='Event Managment'
                icon='profile-circle'
                fontIcon='bi-person'
              >
                <SidebarMenuItem to={`/socialmedias/${eventId}`} title='Social Media Integration' hasBullet={true} />
                <SidebarMenuItem to={`/commiteemembers/${eventId}`} title='Committee Members' hasBullet={true} />
                <SidebarMenuItem to={`/exhibitors/${eventId}`} title='Exhibitors' hasBullet={true} />
                <SidebarMenuItem to={`/Speakers/${eventId}`} title='Speakers' hasBullet={true} />
                <SidebarMenuItem to={`/Sponsonrs/${eventId}`} title='Sponsors' hasBullet={true} />
                <SidebarMenuItem to={`/attendees/${eventId}`} title='Attendees' hasBullet={true} />


              </SidebarMenuItemWithSub>

              <SidebarMenuItemWithSub to={`/stalls/${eventId}`} icon='shop' title='Stalls'>
                <SidebarMenuItem to={`/stalltype/${eventId}`} title='Stall Type' hasBullet={true} />
                <SidebarMenuItem to={`/stallexhibitorList/${eventId}`} title='Stall Exhibitors' hasBullet={true} />
                {/* <SidebarMenuItem to={`/stallChart/${eventId}`} title='Stalls Chart' hasBullet={true} />
               */}
              </SidebarMenuItemWithSub>

              <SidebarMenuItemWithSub title='Tasks' icon='some-files'>
                <SidebarMenuItem to={`/tasklist/${eventId}`} title='Task List' hasBullet={true} />
              </SidebarMenuItemWithSub>
              <SidebarMenuItemWithSub
                to='/events/transaction'
                title='Transaction'
                icon='element-7'
                fontIcon='bi-layers'
              >
                <SidebarMenuItem to='/transaction/payment' title='Payment' hasBullet={true} />
              </SidebarMenuItemWithSub>

              <SidebarMenuItem to={`/events/tasks/${eventId}`} title='Activities' fontIcon='LiaTasksSolid' icon='menu' />
            </div>
          )}


          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Others</span>
            </div>
          </div>

          <SidebarMenuItemWithSub
            title='Transaction'
            icon='element-7'
            fontIcon='bi-layers'
          >
            <SidebarMenuItem to='transactionhistory' title='Transaction History' hasBullet={true} />
          </SidebarMenuItemWithSub>

          {/* <div className='menu-item'>
            
            <SidebarMenuItem
              to={`/live`} icon='code' title='Live Streaming'>

            </SidebarMenuItem>

          </div> */}

        </div> : ""}
    </>
  )
}

export { SidebarMenuMain }
