import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Navbar from "../Navbar";
import { RxLinkedinLogo } from 'react-icons/rx';
import { FaFacebook, FaGooglePlusG } from 'react-icons/fa';
import axios from "axios";
import { toast } from 'react-toastify';
import { useAuth } from "../../../../app/modules/auth";
import Loader from "../../../../Loader";
import { getUserByToken, getUserNotification } from "../../../../app/modules/auth/core/_requests";
function EventSignup() {
  const [event, seteventDetails] = useState({});
  const { orgcode, eventId } = useParams();
  const location = useLocation();

  const [showDetailedInfo, setShowDetailedInfo] = useState(false);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const { auth, saveAuth, setCurrentUser, setNotification } = useAuth();
  const [loading, setLoading] = useState(false);
  // /publicaccounts/authenticate

  useEffect(() => {
    setLoading(true);
    axios.get(API_URL + `/api/EventPlannings/${eventId}`)
      .then(response => {
        seteventDetails(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error fetching eventlist:', error);
        setLoading(false);
      });
  }, [eventId]);

  
  const API_URL = process.env.REACT_APP_API_URL;

  const [userDetails, setUserDetails] = useState({
    username: "",
    password: "",
    role: "",
    eventid: eventId
  });

  const toggleDetailedInfo = () => {
    setShowDetailedInfo(!showDetailedInfo);
  };

  useEffect(() => {
    axios
      .get(`${API_URL}/api/accountRole`)
      .then((response) => {
        //setRoles(response.data);
        const rl = response.data;
        const rolesToDisplay = rl.slice(1);
        setRoles(rolesToDisplay);
      })
      .catch((error) => {
        toast.error(error);
      });
  }, []);


  const handleChange = (event, field) => {
    let actualValue = event.target.value;

    setUserDetails({ ...userDetails, [field]: actualValue });

  };


  function handleOnSubmit() {
    setLoading(true);
    const data = {
      username: userDetails.username,
      password: userDetails.password,
      role: userDetails.role,
      eventid: event?.id
    }
  
  const loginEndpoint = showDetailedInfo ? '/eventmembers/login' : '/publicaccounts/authenticate';
  axios
    .post(API_URL + loginEndpoint, data)
    .then(async (response) => {
      localStorage.setItem("loginpath", location.pathname);
      if (userDetails?.role === 'ROLE_EXHIBITOR') {
        saveAuth(response.data);
        const { data: userdata } = await getUserByToken(auth?.token);
        setCurrentUser(userdata);
        const {data1:noteData}= await getUserNotification(auth?.token);
        setNotification(noteData);
        setLoading(false);
        navigate('/Exhibitordashboard');
      } else
        {
        saveAuth(response.data);
        const { data: userdata } = await getUserByToken(auth?.token);
        setCurrentUser(userdata);
        const {data1:noteData}= await getUserNotification(auth?.token);
        setNotification(noteData);
        setLoading(false);
        navigate('/dashboard');
      }
    })
    .catch((error) => {
      toast.error(error.response?.data?.message || 'An error occurred');
      setLoading(false);
    });
}



return (
  <div>
    {loading ? (
      <Loader />
    ) : (
      <div>
        <Navbar event={event} />
        <div className="mt-10 mx-20 my-20">
          <form onSubmit={handleOnSubmit}>
            <div className="mx-20 my-20">

              <div className="card col-md-12 mt-20 mb-10 text-center">
                <div className="row">
                  <div className="col-md-6 mt-15 mb-15">

                    <h1 className="mb-4 mt-2">Sign in</h1>
                    {/* <div className="mb-4 mx-2">
                      <Link to={`https://accounts.google.com/`}>
                        <FaGooglePlusG className='fs-1 pt-4' style={{ color: '#DB4437' }} size={40} />
                      </Link>

                      <Link to={`https://www.linkedin.com/`}>
                        <RxLinkedinLogo className='fs-1 pt-3' style={{ color: '#1da1f2' }} size={40} /></Link>

                      <Link to={`https://www.facebook.com/`}>
                        <FaFacebook className='fs-1 pt-4' style={{ color: '#3b5998' }} size={40} />
                      </Link>


                    </div> */}
                    {/* <h2 className="mb-4 mt-4"> or use your account</h2> */}

                    <div className="mx-14 my-4">


                      <input
                        className="form-control mb-8 mt-2"
                        type="email"
                        placeholder="Email"
                        name="username"
                        onChange={(e) => handleChange(e, "username")}

                      />

                      {showDetailedInfo && (
                        <>
                          <input
                            className="form-control mb-8 mt-2"
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={(e) => handleChange(e, "password")}
                          />


                          <select
                            className="form-control mb-8 mt-2"
                            type="role"
                            name="role"
                            placeholder="Select role"
                            onChange={(e) => handleChange(e, "role")}>
                            <option> Select Role</option>
                            {roles.map((role) => (
                              <option key={role.id} value={role.role}>
                                {role.role}
                              </option>
                            ))}
                          </select>
                        </>)}

                    </div>
                    <div className="mt-2">
                      <Link className="mx-10" to="/auth/forgot-password">Forgot your password?</Link>
                      <Link className="mx-12" onClick={toggleDetailedInfo}>
                        {showDetailedInfo ? "Login as Attendee?" : "Login as eventmembers?"} </Link>
                    </div>

                    <button className='btn btn-primary mt-10 rounded-pill'>Sign In</button>


                  </div>

                  <div className="col-md-6" style={{ backgroundColor: '#728df9' }}>

                    <div className="mt-15 mb-15">
                      <br></br>

                     

                      <div>
                        <Link to={{ pathname: `/event/${event?.id}/publicregistration` }}> <button
                          className='btn btn-secondary mt-4 mb-4 rounded-pill'
                          id="signUp"

                        >
                          Register Now
                        </button>
                        </Link>
                      </div>

                      <h4 className="mt-2" style={{ color: '#f4f5f8' }}> Registering as a member or volunteer for the event?  <Link to={{ pathname: `/event/${event?.id}/memberSignup` }} style={{ color: '#f4f5f8' }} className="cursor-pointer text-hover-danger"> Click Here</Link></h4>

                    </div>

                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    )}
  </div>
);
}

export default EventSignup;
